import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"
import FeaturedLink from "../components/featuredLink"
import PageCTA from "../components/pageCTA"

export const query = graphql`
  query ServicesPageQuery {
    banner: file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    commercial: file(name: { eq: "bostonian-electric-commercial-electrical" }) {
      childImageSharp {
        gatsbyImageData(width: 512)
      }
    }
    industrial: file(name: { eq: "bostonian-electric-industrial-electrical" }) {
      childImageSharp {
        gatsbyImageData(width: 512)
      }
    }
    residential: file(name: { eq: "bostonian-electric-residential-electrical" }) {
      childImageSharp {
        gatsbyImageData(width: 512)
      }
    }
  }
`

const ServicesPage = ({ data }) => {
  const banner = data.banner.childImageSharp.gatsbyImageData
  const commercial = data.commercial.childImageSharp.gatsbyImageData
  const industrial = data.industrial.childImageSharp.gatsbyImageData
  const residential = data.residential.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Services" />
      <Hero
        image={banner}
        header="Bostonian Electric Services"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <FeaturedLink
          alt="Commercial electrical work"
          heading="Commercial"
          image={commercial}
        >
          <ul className="list-disc">
            <li>HVAC controls</li>
            <li>Security</li>
            <li>Lighting retrofits</li>
            <li>Troubleshooting</li>
          </ul>
        </FeaturedLink>

        <FeaturedLink
          alt="Industrial electrical work"
          heading="Industrial"
          image={industrial}
        >
          <ul className="list-disc">
            <li>HVAC controls</li>
            <li>Security</li>
            <li>Lighting retrofits</li>
            <li>Troubleshooting</li>
          </ul>
        </FeaturedLink>

        <FeaturedLink
          alt="Residential electrical work"
          heading="Residential"
          image={residential}
        >
          <ul className="list-disc">
            <li>Circuit breakers</li>
            <li>Home theater</li>
            <li>Security</li>
            <li>Interior and exterior lighting</li>
          </ul>
        </FeaturedLink>
      </section>
      <PageCTA
          h2="Ready to get started?"
          buttonText="Get a quote"
          destination="/contact/"
        />
    </Layout>
  )
}
export default ServicesPage
